<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Choose a template
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div class="flex gap-8">
          <div class="flex flex-column gap-1 w-3/6">
            <div>Group:</div>
            <b-form-select
              v-model="filters.GroupId"
              :options="options.GroupId"
              size="sm"
            />
          </div>

          <div class="flex flex-column gap-1 w-3/6">
            <div>Search:</div>
            <b-form-input
              v-model="filters.Name"
              placeholder="Search in recipes"
              size="sm"
              autocomplete="off"
              trim
              type="search"
              debounce="500"
              class="search-field"
            ></b-form-input>
          </div>
        </div>

        <table class="table table-google">
          <thead>
            <tr>
              <th>Recipe</th>
              <th>Group</th>
              <th class="row-fit">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ideal in filteredIdeals" :key="ideal.Id">
              <td>{{ ideal.Name }}</td>
              <td class="row-fit">
                <span class="text-nowrap">{{ ideal.GroupName }}</span>
              </td>
              <td class="row-fit">
                <div class="flex gap-2 items-center">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="selectTemplate(ideal)"
                  >
                    Apply
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div></div>
          <div class="flex gap-4">
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'HerbIdealTemplateModal',
  components: {},
  props: {
    data: Object,
    herbid: Number,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    filteredIdeals() {
      return this.ideals
        .filter((ideal) => {
          if (this.filters.GroupId !== null && ideal.GroupId !== this.filters.GroupId) {
            return false;
          }
          if (
            this.filters.Name.length > 0
            && !ideal.Name.toLowerCase().includes(this.filters.Name.toLowerCase())
          ) {
            return false;
          }
          return true;
        })
        .sort((a, b) => {
          const groupComparison = a.GroupName.localeCompare(b.GroupName, undefined, { sensitivity: 'base' });
          if (groupComparison !== 0) {
            return groupComparison;
          }
          return a.Name.localeCompare(b.Name, undefined, { sensitivity: 'base' });
        });
    },
  },
  data() {
    return {
      loadingCount: 0,
      ideals: [],
      filters: {
        GroupId: null,
        Name: '',
      },
      options: {
        GroupId: [],
      },
    };
  },
  methods: {
    buildFilters() {
      if (this.options.GroupId.length === 0) {
        let groups = {};
        this.ideals.forEach((ideal) => {
          groups[ideal.GroupId] = { text: ideal.GroupName, value: ideal.GroupId };
        });
        groups = Object.values(groups);
        groups = groups.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }));
        this.options.GroupId = [
          { text: '', value: null },
          ...groups,
        ];
      }
    },
    handleGroup(groupid) {
      if (this.options.GroupId.length === 0) {
        setTimeout(() => {
          this.handleGroup(groupid);
        }, 250);
        return;
      }
      const groupids = this.options.GroupId.map((group) => group.value);
      if (groupids.includes(groupid)) {
        this.filters.GroupId = groupid;
      }
    },
    fetchGroup() {
      const params = {
        herbids: [this.herbid],
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'herbs' })
        .send({ params })
        .then((res) => {
          const groupid = res.body[0].GroupId;
          this.handleGroup(groupid);
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchIdeals() {
      const params = {};
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.ideal' })
        .send({ method: 'ideals' })
        .send({ params })
        .then((res) => {
          this.ideals = res.body;
          this.buildFilters();
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchGroup();
      this.fetchIdeals();
    },
    selectTemplate(ideal) {
      this.$emit('select', ideal.Id);
      this.onClose();
    },
    onShow() {
      // todo
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss">
</style>
